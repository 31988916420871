// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounting-js": () => import("./../../../src/pages/accounting.js" /* webpackChunkName: "component---src-pages-accounting-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-financial-planning-js": () => import("./../../../src/pages/financial-planning.js" /* webpackChunkName: "component---src-pages-financial-planning-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-small-business-js": () => import("./../../../src/pages/small-business.js" /* webpackChunkName: "component---src-pages-small-business-js" */),
  "component---src-pages-taxation-js": () => import("./../../../src/pages/taxation.js" /* webpackChunkName: "component---src-pages-taxation-js" */)
}

